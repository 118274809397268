
.icon-button{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid grey;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.icon-button:hover {
    background-color: #5d9cec;
    color: white;
    border: 2px solid #5d9cec;
}