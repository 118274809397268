.__scholarship-section {
    background-color: #ecf2ee;
    padding: 30px 0;
    min-height: 60vh;
  } 
  .__mainpage{
    position: relative;
    top: -380px;
  }
  .__title-section {
    margin-bottom: 20px;
  }
  
  .__main-title {
    font-size: 36px;
    font-weight: bold;
    color: #333;
  }
  
  .__sub-title {
    font-size: 16px;
    color: #777;
  }
  

  
  .__banner-image {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  