@import url(https://fonts.googleapis.com/css?family=Raleway);

.swiper-button-next,
.swiper-button-prev {
  color: #121313; 
  
  
}
.swiper-pagination {
    bottom: 10px; 
  }
  
  .swiper-pagination-bullet {
    background: #fffdfd; 
    opacity: 1; 
    transition: background 0.3s ease;
  }
  
  .swiper-pagination-bullet:hover {
    background: #b8a6a6; 
  }
  
  .swiper-pagination-bullet-active {
    background: #313231;
  }

.team-box .content-data {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  
  .team-box .content-data img {
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    opacity: 1;
    visibility: visible;
  }
  
  .team-box:hover .content-data img {
    opacity: 0.75;
    /* visibility: hidden; */
  }
  
  .team-box .team-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.7s ease-in-out;
    background-color: rgba(12, 12, 12, 0.3); 
    color: rgb(238, 235, 235);
    padding: 20px;
  }
  
  .team-box:hover .team-content {
    opacity: 1;
  }
  .card_overlay {
    background-color: rgba(0, 0, 0, 0.5); 
    border-radius: 10px;
    overflow: hidden;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .image_container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: 1;
    opacity: 0.3; 
    filter: blur(3px);
  }
  
  .training_image {
    background-image: url('../../../assets/images/Events/gymfile.jpg');
  }
  
  .consult_image {
    background-image: url('../../../assets/images/Diet/img1.png'); /* Replace with actual image URL */
  }
  
  .card_title {
    position: relative;
    z-index: 2; /* Bring text above the image */
    color: rgb(1, 1, 1);
    font-size: 1.8rem;
  }
  
  .highlight {
    color: orange;
  }
  
  .text_center {
    text-align: center;
  }
  
  .row_ {
    margin-top: 40px;
  }
  
  .card_body {
    position: relative;
    z-index: 2;
  }
  .card-flip {
    perspective: 1000px;
    width: 100%;
    max-width: 300px;
    position: relative;
}

.cursor-pointer{
  cursor: pointer;
}
.card-flip .flip-card-front,
.card-flip .flip-card-back {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    transition: transform 0.8s;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.card-flip .flip-card-front {
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-flip .flip-card-back {
    background-size: cover;
    background-position: center;
    height: 200px;
    transform: rotateY(180deg);
    color: white;
}

.card-flip:hover .flip-card-front {
    transform: rotateY(-180deg);
}

.card-flip:hover .flip-card-back {
    transform: rotateY(0deg);
}
.overlay-text-container {
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 10px;
  border-radius: 8px;
  color: white; 
}

.subscription-section {
  position: relative;
  background-image: url('../../../assets/images/slider/img1.jpg'); 
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  height: 300px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); 
  padding: 40px 0;
  z-index: 1;
}

.subscription-section .container {
  position: relative;
  z-index: 2; 
}

.text-light {
  color: white; 
}
/* Main heading (bold and impactful) */
.main-heading {
  font-size: 3rem;
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 20px;
}

.main-heading .highlight {
  color: #ff6600;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease;
}

.main-heading .highlight:hover {
  color: #ffd700; 
  transition: color 0.3s ease;
}
.description {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  color: #bbb;
}

@media (max-width: 768px) {
  .main-heading {
    font-size: 2rem;
  }

  .description {
    font-size: 0.9rem;
  }
}

/* Timetable container styling */
.timetable_schedule {
  background-color: #111;
  padding: 20px;
  color: #fff;
}

.header_schedule {
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0;
  border-bottom: 2px solid #444;
}

.time-col_schedule {
  font-weight: bold;
  color: #ff6600;
}

.schedule-row_schedule {
  padding: 10px 0;
  border-bottom: 1px solid #333;
}

.schedule-row_schedule .col_schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active_schedule {
  background-color: #c49a6c;
  padding: 15px;
  border-radius: 5px;
  color: #111;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.active_schedule span {
  font-size: 0.9rem;
  color: #555;
  margin-top: 5px;
}

.active_schedule:hover {
  background-color: #ff6600;
  color: #fff;
  cursor: pointer;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .schedule-row_schedule .col_schedule {
    font-size: 0.9rem;
  }
  .subscription-section{
    margin-top: 20px;
  }
}
@media (max-width: 567px) {
  .schedule-row_schedule .col_schedule {
    font-size: 0.9rem;
  }
  .subscription-section{
    margin-top: 50px;
  }
}
.investors-section {
  background-color: #2b2b2b; 
  padding: 40px 0;
}

.investors-title {
  font-size: 2rem;
  font-weight: bold;
  color: #ccc; 
  letter-spacing: 2px;
  margin-bottom: 30px; 
}

.investor-card {
  background-color: #5e5e5e; 
  border-radius: 15px;
  padding: 20px;
  color: #fff; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s, box-shadow 0.3s; 
  height: 400px; 
  display: flex; 
  flex-direction: column; 
  cursor: pointer;
}

.investor-card:hover {
  transform: translateY(-3px); 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); 
}

.investor-body {
  position: relative; 
  z-index: 1; 
  flex-grow: 1; 
  display: flex; 
  justify-content: center; 
}

.overlay {
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  background: rgba(0, 0, 0, 0.5); 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  color: white; 
  padding: 20px;
  border-radius: 15px; 
}

.highlight-text {
  color: #ff9f43; 
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7); 
}

.vertical-divider {
  display: flex;
  align-items: center;
  height: 300px;
  position: relative;
}

.vertical-divider::before, 
.vertical-divider::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #bbb; 
}

.vertical-divider::before {
  left: 50%;
  transform: translateX(-3px); 
}

.vertical-divider::after {
  left: 50%;
  transform: translateX(3px); 
}

@media (max-width: 768px) {
  .vertical-divider {
    display: none; 
  }
}


/* General Section Styling */
.social-media-section {
  background-color: #f9f9f9; 
  padding: 50px 0;
}

.section-title {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
}

.tabs {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.tab {
  color: #333;
  cursor: pointer;
  padding: 5px 15px;
  position: relative;
}

.tab.active-tab {
  color: #131212; 
}

.tab:not(.active-tab)::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #333;
  bottom: -10px;
  left: 0;
}

/* Card Styling */
.media-card {
  background-color: #333; 
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  height: 400px;
  transition: transform 0.3s;
}

.media-card:hover {
  transform: translateY(-10px);
}

.media-platform {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fcfcfc; 
}

.media-heading {
  font-size: 1rem;
  margin-top: 10px;
  font-weight: 500;
  color: #ccc; /* Light text for heading */
}

.btn-view-more {
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
}

.btn-view-more:hover {
  background-color: #ff9800; /* Orange highlight on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .tabs {
    font-size: 1rem;
  }

  .tab {
    margin: 0 10px;
  }

  .media-card {
    margin-bottom: 20px;
  }
}

.custom-card {
  background-color: #343a40;
  color: white;
  border-radius: 5px;
  position: relative;
}

.image-container {
  height: 200px;
  overflow: hidden;
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.like-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
}

.like-btn i {
  font-size: 24px;
  color: #fff;
}

.custom-card-body {
  height: 125px;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.liked i {
  color: red; /* Change color when liked */
}
.card-background {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.card-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); 
  z-index: 1; 
}

.text-container {
  position: relative; 
  z-index: 2; 
}

.carousel-control {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  border-radius: 50%; /* Round button */
  width: 40px; /* Set width */
  height: 40px; /* Set height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-control:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
}

.text-dark {
  color: #fff; /* Ensure the text is white for visibility */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1); /* Invert icon colors for better contrast */
}
