.landing-page-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .uploaded-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .image-container {
    position: relative;
    display: inline-block;
  }
  
  .uploaded-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .uploaded-image:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  
  .remove-image-btn {
    position: absolute;
    top: 2px;
    right: 5px;
    background: rgb(242, 120, 120);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 2px;
  }
  
  .limit-text {
    color: rgb(242, 149, 149);
    font-size: 0.9rem;
    margin-top: 10px;
  }
  