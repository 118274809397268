.__consulting-overview {
    padding: 40px 0;
  }
  
  /* Program Image */
  .__program-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Consulting Services */
  .__consulting-services {
    background-color: #f4f7fc;
    padding: 50px 0;
  }
  
  .__services-image-container {
    padding: 20px;
    border: 2px solid #ddd;
    border-radius: 8px;
  }
  
  .__services-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .__services-content {
    padding: 20px;
  }
  
  .__services-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 20px;
  }
  
  .__services-list {
    list-style: none;
    padding: 0;
  }
  
  .__services-list li {
    font-size: 1rem;
    color: #4a4a4a;
    margin-bottom: 10px;
  }
  
  /* Call to Action */
  .__consultation-cta {
    background-color: #fff;
    padding: 30px;
    margin-top: 50px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .__cta-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .__cta-description {
    font-size: 1rem;
    color: #5a5a5a;
    margin-bottom: 20px;
  }
  
  .__cta-button {
    background-color: #007bff;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .__cta-button:hover {
    background-color: #0056b3;
  }