/* Wrapper for the entire component */
.__assessment-wrapper {
    position: relative;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #ffffff;
    font-family: Arial, sans-serif;
  }
  
  /* Background Image */
  .__assessment-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }
  
  .__assessment-bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Gradient Overlay */
  .__gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 0, 150, 0.5), rgba(0, 150, 255, 0.5));
    z-index: 2;
  }
  
  /* Content Container */
  .__assessment-content {
    position: relative;
    z-index: 3;
    text-align: center;
  }
  
  /* Title Styling */
  .__assessment-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Author Section */
  .__assessment-author {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .__author-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .__author-text {
    font-size: 1rem;
    font-weight: 500;
  }
  