.__team-section {
    padding: 50px 0;
    background-color: #f8f9fa;
  }
  
  .__team-title {
    font-size: 2.5rem;
    color: #343a40;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .__team-title span {
    color: #28a745; /* Highlight color */
  }
  
  .__team-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .__team-card:hover {
    transform: translateY(-10px);
  }
  
  .__team-img-wrapper {
    text-align: center;
    padding-top: 20px;
  }
  
  .__team-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #28a745; /* Green border */
  }
  
  .__team-card h5 {
    font-size: 1.25rem;
    margin-top: 15px;
    color: #343a40;
  }
  
  .__team-card h6 {
    font-size: 1rem;
    color: #6c757d;
    margin-top: 5px;
  }
  