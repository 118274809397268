.calculator-section {
  background-color: #f4f4f4;
  padding: 20px;
}

.__header-row {
  margin-bottom: 20px;
}

.__header-title {
  font-size: 2em;
  font-weight: bold;
  color: #0b3d91;
}

.__arrow {
  font-size: 1.5em;
  color: #f7c531;
}

.icon-row {
  margin-bottom: 30px;
}

.icon-col i {
  font-size: 2em;
  color: #0b3d91;
}

.calculator-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.__calculator-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.__calculator-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.__calculator-icon {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.__calculator-text {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}
