.__sponsorship-container {
    padding: 40px;
    background-color: #fff;
  }
  
  .__meta-info {
    font-size: 14px;
    color: #777;
  }
  
  .__meta-date {
    font-weight: bold;
    color: #333;
  }
  
  .__main-title {
    font-size: 32px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #000;
  }
  
  .__image-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .__sponsorship-image {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  