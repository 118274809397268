.__social-icons a {
    font-size: 30px;
    /* Size of the icons */
    color: #495057;
    /* Icon color */
    margin: 0 15px;
    /* Spacing between icons */
    transition: color 0.3s;
    /* Smooth transition for hover effect */
}

.__social-icons a:hover {
    color: #007bff;
    /* Change color on hover */
}

/* Search Bar */
.__search-bar-wrapper {
    max-width: 700px;
    margin: auto;
}

.__search-input {
    flex: 1;
    border-radius: 25px;
    padding: 10px 20px;
    margin-right: 10px;
}

.__search-button {
    border-radius: 25px;
    padding: 10px 20px;
}

/* Card Styles */
.__exercise-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.__exercise-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.__image-wrapper {
    position: relative;
}

.__exercise-image {
    height: 250px;
    object-fit: cover;
    border-bottom: 2px solid #eaeaea;
}

/* Heart Icon */
.__heart-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: rgba(255, 0, 0, 0.7);
    /* Semi-transparent red */
    background-color: rgba(255, 255, 255, 0.6);
    /* Transparent white background */
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.__heart-icon:hover {
    color: red;
    background-color: rgba(255, 255, 255, 0.9);
}

.__exercise-category {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.__exercise-date {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
}

.__exercise-title {
    font-size: 16px;
    color: #444;
    margin-bottom: 20px;
}

/* Show Details Button */
.__show-button {
    padding: 10px 20px;
    /* background-color: rgb(52 137 130); */
    border-radius: 25px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.media-position {
    position: fixed !important;
    right: 10px;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .media-position {
        position: static !important;
    }
}

@media (max-width: 567px) {
    .media-position {
        position: static !important;
    }
}

.advanced-search-panel {
    width: 400px;
    position: absolute;
    top: 200px;
    z-index: 1050;
    right: 10px;
    background-color: #f8f9fa;
    border-left: 1px solid #ddd;
}

@media (max-width: 768px) {
    .advanced-search-panel {
        z-index: 1050;
    }
}

@media (max-width: 567px) {
    .advanced-search-panel {
        z-index: 1050;
    }
}

.blinking-text {
    color: yellowgreen;
    font-size: 15px;
    font-weight: 700;
    animation: blink 1s infinite;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.scrollable-col {
    height: 100vh;
    overflow-y: hidden; /* Hide scrollbar by default */
    overflow-x: hidden; /* Optional: Hide horizontal scrollbar */
    transition: overflow 0.2s; /* Smooth transition for scrollbar appearance */
}

.scrollable-col:hover {
    overflow-y: auto; /* Show vertical scrollbar on hover */
}
.scrollable-col::-webkit-scrollbar {
    width: 7px; /* Width of the scrollbar */
}
.scrollable-col::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the track */
    border-radius: 10px; /* Optional: rounded corners */
}

.scrollable-col::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar thumb color */
    border-radius: 10px; /* Optional: rounded corners */
}

.scrollable-col::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb color on hover */
}