.__mission-section {
    padding: 60px 0;
    background-color: #f8f9fa; /* Light background for contrast */
  }
  
  .__mission-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #343a40;
  }
  
  .__mission-highlight {
    color: #28a745; /* Green highlight to match the design */
  }
  
  .__mission-subtitle {
    font-size: 1.25rem;
    color: #6c757d;
    margin-top: 15px;
    max-width: 700px;
    margin: 0 auto;
  }
  
  .__mission-img-wrapper {
    text-align: center;
    margin-top: 30px;
  }
  
  .__mission-img-wrapper img {
    max-width: 100%;
    height: auto;
    border-radius: 15px; /* Rounded corners for the image */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Adding some shadow for depth */
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .__mission-title {
      font-size: 2rem;
    }
  
    .__mission-subtitle {
      font-size: 1rem;
    }
  }
  