/* AboutUsPage.css */

/* General Styling */
.page-content {
    padding: 20px;
}

.about-text h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;

    width: 500px;
}

.about-text p {
    font-size: 1.1rem;
    line-height: 1.6;
}

.luxury-carousel {
    position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 2rem;
}

.luxury-image {
    object-fit: cover;
    height: 500px;
    width: 100%;
    border-radius: 8px;
}

/* Carousel Effects */
.fade-effect .carouselItem {
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.fade-effect .carousel-item.active {
    opacity: 1;
}

.zoom-effect .luxury-image {
    transform: scale(0.9);
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    opacity: 0;
}

.zoom-effect.active .luxury-image {
    transform: scale(1);
    opacity: 1;
}

.slide-effect .carousel-item {
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    transform: translateX(-100%);
    opacity: 0;
}

.slide-effect .carousel-item.active {
    transform: translateX(0);
    opacity: 1;
}

/* Animation 1: Fade-in */
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Animation 2: Zoom-in */
  @keyframes zoomIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Animation 3: Slide-up */
  @keyframes slideUp {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

/* Control Button Styling */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10%;
    padding: 10px;
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%;
}

.carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5); 
    padding: 20px;
    color: #fff;
    text-align: center;
    border-radius: 10px;
}

.carousel-caption h3 {
    font-size: 2rem;
    font-weight: bold;
}

.carousel-caption p {
    font-size: 1.2rem;
}

.contact-button {
    background-color: #ff6347;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.contact-button:hover {
    background-color: #ff4500;
}
.side-card{
    background: #d0c8b7;
    color:white;
    width: 673px;
    height: 585px;
    color:#805e18;
    z-index: 5;
}
.__text-card{
    color:#805e18;
    width: 550px;
}